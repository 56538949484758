import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { AppConstant } from "../core/constants/enums";
import { StorageService } from "../core/store/storage.service";

@Injectable()
export class RoleService {
  private isLoginAsAdmin = new BehaviorSubject(false);
  currentRoleDetails = this.isLoginAsAdmin.asObservable();
  private role = new BehaviorSubject("private");
  private userName = new BehaviorSubject("");
  userNameShow = this.userName.asObservable();
  private userLogo = new BehaviorSubject("assets/images/nav/Avatar.svg");
  user_logo_url = this.userLogo.asObservable();
  getProfileData: any = null;

  private providerObjectDetails = new BehaviorSubject({
    logo_url: "",
    organization_name: "",
    role: "",
  });
  providerObj = this.providerObjectDetails.asObservable();

  constructor(private router: Router, private storage: StorageService) {
    let role: any = this.storage?.getUserRole;
    let userName: any = this.storage?.getUserNameShow;
    this.changeUserName(userName);
    this.changeRole(role);
    let providerObj = this.storage?.getProviderDetails;
    if (providerObj != null && providerObj != undefined) {
      this.changeProviderObj(providerObj);
    }
  }
  changeRole(role: string) {
    this.role.next(role);
  }
  changeUserName(name: string) {
    this.userName.next(name);
  }

  checkRouteAllow() {
    let flag = true;
    let route = this.router.url;
    let role = this.storage?.getUserRole;

    if (role == AppConstant.ADMIN_ROLE || role == AppConstant.LEADER_ROLE) {
      if (
        route == "/tasks" ||
        route == "/tasks/recent" ||
        route == "/tasks/inactive"
      ) {
        flag = false;
      }
    }
    if (role == AppConstant.PHYSICIAN_ROLE || role == AppConstant.MEMBER_ROLE) {
      if (
        route?.includes("/users") ||
        route == "/invite" ||
        route == "/imports" ||
        route.includes("/reports") ||
        route.includes("/groups") ||
        route.includes("/dashboard/new") ||
        route.includes("/users")
      ) {
        flag = false;
      }
    }
    return flag;
  }

  changeLoginAs(flag: boolean) {
    this.isLoginAsAdmin.next(flag);
  }

  changeProviderObj(obj: any) {
    this.providerObjectDetails.next(obj);
  }

  changeUserProfileLogo(logo: any) {
    this.userLogo.next(logo);
  }
}
