import { Injectable, signal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SignalService {
  private userTypeSignal = signal<boolean>(false);

  constructor() {}

  get userType() {
    return this.userTypeSignal;
  }

  setUserType(newType: boolean) {
    this.userTypeSignal.set(newType);
  }
}
